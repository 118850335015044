import logoLight from "@/images/seasons/fertilelogogreen.png";
import logoDark from "@/images/logo2.png";

export const LogoImage = {
  light: logoLight,
  dark: logoDark,
};

export const NavLinks = [
  {
    name: "Home",
    url: "/",
    // subItems: [
    //   {
    //     name: "Home 01",
    //     url: "/",
    //   },
    //   {
    //     name: "Home 02",
    //     url: "/index-2",
    //   },
    //   {
    //     name: "Home 03",
    //     url: "/index-3",
    //   },
    // ],
  },
  {
    name: "About Us",
    url: "/about-us",
  },
  {
    name: "Services",
    url: "/service",
    // subItems: [
    //   {
    //     name: "Services",
    //     url: "/service",
    //   },
    //   {
    //     name: "Service Details",
    //     url: "/service-details",
    //   },
    // ],
  },
  {
    name: "Gallery",
    url: "/gallery",
    // subItems: [
    //   {
    //     name: "Portfolio 01",
    //     url: "/portfolio",
    //   },
    //   {
    //     name: "Portfolio Details",
    //     url: "/portfolio-details",
    //   },
    // ],
  },
  {
    name: "Gift Cards",
    url: "/gift-cards",
    // subItems: [
    //   {
    //     name: "Blog 01",
    //     url: "/blog",
    //   },
    //   {
    //     name: "Blog 02",
    //     url: "/blog-2",
    //   },
    //   {
    //     name: "Blog 03",
    //     url: "/blog-3",
    //   },
    //   {
    //     name: "Blog Details",
    //     url: "/blog-single",
    //   },
    // ],
  },
  {
    name: "Contact",
    url: "/contact",
  },
];
import sub1 from "@/images/seasons/fertilefallhome1.png";

import sliderOne01 from "@/images/seasons/fertilefallbackground.jpg";
import sliderOne02 from "@/images/seasons/fertilespringbackground.jpg";
import sliderOne03 from "@/images/seasons/fertilesummerbackground.jpg";
import sliderOne04 from "@/images/seasons/fertilewinterbackground.jpg";
import sub2 from "@/images/seasons/fertilespringhome1.png";
import sub3 from "@/images/seasons/fertilesummerhome1.png";
import sub4 from "@/images/seasons/fertilewinterhome1.png";

export const SliderOneData = [
  {
    image: sliderOne01,
    subImage: sub1,
    subTitle: "WELCOME TO MEIPALY AGENCY",
    title: "FALL",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
  {
    image: sliderOne02,
    subImage: sub2,
    subTitle: "WELCOME TO MEIPALY AGENCY",
    title: "SPRING",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
  {
    image: sliderOne03,
    subImage: sub3,
    subTitle: "WELCOME TO MEIPALY AGENCY",
    title: "SUMMER",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
  {
    image: sliderOne04,
    subImage: sub4,
    subTitle: "WELCOME TO MEIPALY AGENCY",
    title: "WINTER",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
];

import sliderTwo01 from "@/images/slider/2_1.jpg";
import sliderTwo02 from "@/images/slider/2_2.jpg";
import sliderTwo03 from "@/images/slider/2_3.jpg";

export const SliderTwoData = [
  {
    image: sliderTwo01,
    subTitle: "WELCOME TO MEIPALY AGENCY",
    title: "DIGITAL\nMARKETING\nAGENCY.",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
  {
    image: sliderTwo02,
    subTitle: "WELCOME TO MEIPALY AGENCY",
    title: "DIGITAL\nMARKETING\nAGENCY.",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
  {
    image: sliderTwo03,
    subTitle: "WELCOME TO MEIPALY AGENCY",
    title: "DIGITAL\nMARKETING\nAGENCY.",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
];

import sliderThree01 from "@/images/slider/3_1.jpg";
import sliderThree02 from "@/images/slider/3_2.jpg";

export const SliderThreeData = [
  {
    image: sliderThree01,
    subTitle: "WELCOME TO MEIPALY AGENCY",
    title: "SMART WEB\n DESIGN AGENCY.",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
  {
    image: sliderThree02,
    subTitle: "WELCOME TO MEIPALY AGENCY",
    title: "SMART WEB\n DESIGN AGENCY.",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
];

export const ContactInfosBlock = {
  subTitle: "our offices",
  title: "offices near you",
  description:
    "We are committed to providing our customers with exceptional service while \n offering our employees the best training.",
};

export const ContactInfosList = [
  {
    title: "austin",
    infos: [
      {
        name: "22 Texas West Hills",
      },
      {
        name: "needhelp@meipaly.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
  {
    title: "boston",
    infos: [
      {
        name: "5 Federal Street Boston",
      },
      {
        name: "needhelp@meipaly.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
  {
    title: "new york",
    infos: [
      {
        name: "8th Broklyn New York",
      },
      {
        name: "needhelp@meipaly.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
  {
    title: "baltimore",
    infos: [
      {
        name: "3 Lombabr 50 baltimore",
      },
      {
        name: "needhelp@meipaly.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
];

export const ContactFormTitle = {
  subTitle: "Contact with us",
  title: "write us a message",
  description: "We are committed to meeting your gardening needs ",
};

import blogImage1 from "@/images/blog/1.jpg";
import blogImage2 from "@/images/blog/2.jpg";
import blogImage3 from "@/images/blog/3.jpg";
import blogImage4 from "@/images/blog/4.jpg";
import blogImage5 from "@/images/blog/5.jpg";
import blogImage6 from "@/images/blog/6.jpg";

export const BlogData = [
  {
    title: "basic rules of running web agency business",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage1,
  },
  {
    title: "Become the best sale marketer",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage2,
  },
  {
    title: "Introducing latest mopaly features",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage3,
  },
  {
    title: "a deep understanding of our audience",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage4,
  },
  {
    title: "We build and activate brands insight",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage5,
  },
  {
    title: "experiences that connect with people",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage6,
  },
];

import blogImageTwo1 from "@/images/blog/14.jpg";
import blogImageTwo2 from "@/images/blog/15.jpg";

export const BlogTwoData = [
  {
    title:
      "Dynamically procrastinate unique vortals with global best practices.",
    date: "20 nov",
    url: "/blog-single",
    image: blogImageTwo1,
  },
  {
    title: "Become the best sale marketer",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage2,
  },
  {
    title: "Introducing latest mopaly features",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage3,
  },
  {
    title: "a deep understanding of our audience",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage4,
  },
  {
    title: "We build and activate brands insight",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage5,
  },
  {
    title:
      "Holisticly conceptualize backend scenarios via accurate technologies.",
    date: "20 nov",
    url: "/blog-single",
    image: blogImageTwo2,
  },
  {
    title: "experiences that connect with people",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage6,
  },
];

export const BlogHomeSection = {
  subTitle: "our news & articles",
  title: "latest blog posts",
  text:
    "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
};

import blogS1 from "@/images/blog/11.jpg";
import blogS2 from "@/images/blog/12.jpg";
import blogS3 from "@/images/blog/13.jpg";

export const BlogSidebarPost = [
  {
    title: "basic rules of running web agency",
    image: blogS1,
    url: "/blog-single",
  },
  {
    title: "basic rules of running web agency",
    image: blogS2,
    url: "/blog-single",
  },
  {
    title: "basic rules of running web agency",
    image: blogS3,
    url: "/blog-single",
  },
];

import commentImage1 from "@/images/blog/9.jpg";
import commentImage2 from "@/images/blog/10.jpg";

export const BlogComments = [
  {
    image: commentImage1,
    name: "David Martin",
    data: "20 Nov, 2018 - 4:00 pm",
    content:
      "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et not the aliquet.",
  },
  {
    image: commentImage2,
    name: "Jessica Brown",
    data: "20 Nov, 2018 - 4:00 pm",
    content:
      "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et not the aliquet.",
  },
];

import portfolio01 from "@/images/gallery/fall/fall1 (1).jpeg";
// import portfolio02 from "@/images/gallery/fall/fall1 (2).jpeg";
import portfolio03 from "@/images/gallery/fall/fall1 (3).jpeg";
import portfolio04 from "@/images/gallery/fall/fall1 (4).jpeg";
import portfolio05 from "@/images/gallery/fall/fall1 (5).jpeg";
import portfolio06 from "@/images/gallery/fall/fall1 (6).jpeg";
import portfolio07 from "@/images/gallery/fall/fall1 (7).jpeg";
import portfolio28 from "@/images/gallery/fall/fall2 (1).jpg";
import portfolio29 from "@/images/gallery/fall/fall2 (2).jpg";
import portfolio30 from "@/images/gallery/fall/fall2 (3).jpg";
import portfolio31 from "@/images/gallery/fall/fall2 (4).jpg";

import portfolio08 from "@/images/gallery/springandsummer/springandsummer (1).jpeg";
import portfolio09 from "@/images/gallery/springandsummer/springandsummer (2).jpeg";
import portfolio10 from "@/images/gallery/springandsummer/springandsummer (3).jpeg";
import portfolio11 from "@/images/gallery/springandsummer/springandsummer (4).jpeg";
import portfolio12 from "@/images/gallery/springandsummer/springandsummer (5).jpeg";
import portfolio13 from "@/images/gallery/springandsummer/springandsummer (6).jpeg";
import portfolio14 from "@/images/gallery/springandsummer/springandsummer (7).jpeg";
import portfolio15 from "@/images/gallery/springandsummer/springandsummer (8).jpeg";
import portfolio16 from "@/images/gallery/springandsummer/springandsummer (9).jpeg";
import portfolio17 from "@/images/gallery/springandsummer/springandsummer (10).jpeg";
import portfolio18 from "@/images/gallery/springandsummer/springandsummer (11).jpeg";
import portfolio19 from "@/images/gallery/springandsummer/springandsummer (12).jpeg";
import portfolio20 from "@/images/gallery/springandsummer/springandsummer (13).jpeg";
import portfolio21 from "@/images/gallery/springandsummer/springandsummer (14).jpeg";

export const PortfolioData = [
  {
    title: "Fall",
    // categories: ["all", "graphic"],
    image: portfolio01,
    // url: "/portfolio-details",
  },
  {
    title: "Fall",
    // categories: ["all", "graphic"],
    image: portfolio28,
    // url: "/portfolio-details",
  },
  {
    title: "Fall",
    // categories: ["all", "graphic"],
    image: portfolio29,
    // url: "/portfolio-details",
  },
  {
    title: "Fall",
    // categories: ["all", "graphic"],
    image: portfolio30,
    // url: "/portfolio-details",
  },
  {
    title: "Fall",
    // categories: ["all", "graphic"],
    image: portfolio31,
    // url: "/portfolio-details",
  },
  // {
  //   title: "Fall",
  //   // categories: ["all", "graphic"],
  //   image: portfolio02,
  //   // url: "/portfolio-details",
  // },
  {
    title: "Fall",
    // categories: ["all", "graphic"],
    image: portfolio03,
    // url: "/portfolio-details",
  },
  {
    title: "Fall",
    // categories: ["all", "graphic"],
    image: portfolio04,
    // url: "/portfolio-details",
  },
  {
    title: "Fall",
    // categories: ["all", "graphic"],
    image: portfolio05,
    // url: "/portfolio-details",
  },
  {
    title: "Fall",
    // categories: ["all", "graphic"],
    image: portfolio06,
    // url: "/portfolio-details",
  },
  {
    title: "Fall",
    // categories: ["all", "graphic"],
    image: portfolio07,
    // url: "/portfolio-details",
  },
  {
    title: "Spring and Summer",
    // categories: ["all", "graphic"],
    image: portfolio08,
    // url: "/portfolio-details",
  },
  {
    title: "Spring and Summer",
    // categories: ["all", "graphic"],
    image: portfolio09,
    // url: "/portfolio-details",
  },
  {
    title: "Spring and Summer",
    // categories: ["all", "graphic"],
    image: portfolio10,
    // url: "/portfolio-details",
  },
  {
    title: "Spring and Summer",
    // categories: ["all", "graphic"],
    image: portfolio11,
    // url: "/portfolio-details",
  },
  {
    title: "Spring and Summer",
    // categories: ["all", "graphic"],
    image: portfolio12,
    // url: "/portfolio-details",
  },
  {
    title: "Spring and Summer",
    // categories: ["all", "graphic"],
    image: portfolio13,
    // url: "/portfolio-details",
  },
  {
    title: "Spring and Summer",
    // categories: ["all", "graphic"],
    image: portfolio14,
    // url: "/portfolio-details",
  },
  {
    title: "Spring and Summer",
    // categories: ["all", "graphic"],
    image: portfolio15,
    // url: "/portfolio-details",
  },
  {
    title: "Spring and Summer",
    // categories: ["all", "graphic"],
    image: portfolio16,
    // url: "/portfolio-details",
  },
  {
    title: "Spring and Summer",
    // categories: ["all", "graphic"],
    image: portfolio17,
    // url: "/portfolio-details",
  },
  {
    title: "Spring and Summer",
    // categories: ["all", "graphic"],
    image: portfolio18,
    // url: "/portfolio-details",
  },
  {
    title: "Spring and Summer",
    // categories: ["all", "graphic"],
    image: portfolio19,
    // url: "/portfolio-details",
  },
  {
    title: "Spring and Summer",
    // categories: ["all", "graphic"],
    image: portfolio20,
    // url: "/portfolio-details",
  },
  {
    title: "Spring and Summer",
    // categories: ["all", "graphic"],
    image: portfolio21,
    // url: "/portfolio-details",
  },
];

export const PortfolioFilters = [
  { name: "all" },
  { name: "graphic" },
  { name: "branding" },
  { name: "marketing" },
  { name: "logos" },
];

import portfolioD01 from "@/images/portfolio/7.jpg";
import portfolioD02 from "@/images/portfolio/8.jpg";

export const PortfolioDetailsData = {
  gallery: [{ image: portfolioD01 }, { image: portfolioD02 }],
  title: "Design Styles",
  text:
    " Tincidunt elit magnis nulla facilisis sagittis sapien nunc amet ultrices dolores sit ipsum velit purus aliquet massa fringilla leo orci. Sapien nunc amet ultrices, dolores sit ipsum velit purus aliquet, massa fringilla leo orci dolors sit amet elit amet. ",
  client: "Jessica Brown",
  categories: [
    {
      name: "Graphic,",
      url: "#",
    },
    {
      name: "Illustrations,",
      url: "#",
    },
  ],
  date: "8 Nov, 2018",
  socials: [
    {
      name: "Facebook",
      url: "#",
    },
    {
      name: "Twitter",
      url: "#",
    },
    {
      name: "Linkedin",
      url: "#",
    },
  ],
};

export const ServicePostData = [
  {
    title: "Indoor Plants/Cut Flowers",
    text: "Bring the beauty of nature indoors.",
    iconName: "mei-flower",
    // url: "/service-details",
  },
  {
    title: "Landscaping Design",
    text: "We can tailor your outdoor spaces to your needs.",
    iconName: "mei-settings",
    // url: "/service-details",
  },
  {
    title: "Garden Design/Installations",
    text:
      "We will bring our expertise to build beautiful gardens and installations.",
    iconName: "mei-transfer",
    // url: "/service-details",
  },
  {
    title: "Seasonal Containers",
    text: "Bring seasonal charm to your spaces.",
    iconName: "mei-flower",
    // url: "/service-details",
  },
  {
    title: "Maintenance",
    text: "Maintenance including cleanups, trimming, and mulch",
    iconName: "mei-flower",
    // url: "/service-details",
  },
];

export const ServiceHomeTwoData = {
  subTitle: "welcome to smart meipaly web agency",
  title: "We design digital products that \n help grow businesses.",
  text:
    "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
};

export const ServiceHomeThreeData = {
  subTitle: "Services we are offering",
  title: "Our Services",
  text:
    "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
};

export const ServicePostTwoData = {
  sectionContent: {
    title: "We design digital products that \n help grow businesses.",
    subTitle: "Services we are offering",
    text:
      "We are committed to providing our customers with exceptional service \n while offering our employees the best training.",
  },
  posts: [
    {
      title: "Website Development",
      iconName: "mei-web-design",
      url: "/service-details",
    },
    {
      title: "Graphic Designing",
      iconName: "mei-computer-graphic",
      url: "/service-details",
    },
    {
      title: "Digital Marketing",
      iconName: "mei-development-1",
      url: "/service-details",
    },
    {
      title: "SEo & Content Writing",
      iconName: "mei-development",
      url: "/service-details",
    },
    {
      title: "App Development",
      iconName: "mei-app-development",
      url: "/service-details",
    },
  ],
};

import serviceOne01 from "@/images/home_1/6.jpg";
import serviceOne02 from "@/images/home_1/7.jpg";
import serviceOne03 from "@/images/home_1/8.jpg";

export const ServicePostThreeData = {
  sectionContent: {
    title: "Let’s create something",
    subTitle: "what we do",
    text:
      "We are committed to providing our customers with exceptional service \n while offering our employees the best training.",
  },
  posts: [
    {
      title: "web development",
      image: serviceOne01,
      url: "/service-details",
    },
    {
      title: "digital marketing",
      image: serviceOne02,
      url: "/service-details",
    },
    {
      title: "product branding",
      image: serviceOne03,
      url: "/service-details",
    },
  ],
};

export const FunfactData = [
  {
    title: "Projects Completed",
    countNumber: 705,
  },
  {
    title: "Active Clients",
    countNumber: 480,
  },
  {
    title: "Cups of Coffee",
    countNumber: 626,
  },
  {
    title: "Happy Clients",
    countNumber: 774,
  },
];

import trustClient01 from "@/images/home_1/4.jpg";

export const TrustClientData = {
  image: trustClient01,
  title: "We are trusted by more than 8900 clients",
  text:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi-dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercita-tion ullamco laboris nisi ut aliquip ex ea commodo.",
  url: "/about",
};

import ClientCarousel01 from "@/images/client/1.png";
import ClientCarousel02 from "@/images/client/2.png";
import ClientCarousel03 from "@/images/client/3.png";
import ClientCarousel04 from "@/images/client/4.png";
import ClientCarousel05 from "@/images/client/5.png";

export const ClientCarouselData = {
  sectionContent: {
    title: "they trust us",
    subTitle: "our clients",
    text:
      "We are committed to providing our customers with exceptional service \n while offering our employees the best training.",
  },
  items: [
    {
      url: "#",
      image: ClientCarousel01,
    },
    {
      url: "#",
      image: ClientCarousel02,
    },
    {
      url: "#",
      image: ClientCarousel03,
    },
    {
      url: "#",
      image: ClientCarousel04,
    },
    {
      url: "#",
      image: ClientCarousel05,
    },
  ],
};

import aboutOne01 from "@/images/about/1.jpg";
import aboutOne02 from "@/images/about/2.jpg";

export const AboutOneData = {
  sectionContent: {
    title: "build better website alot quicker with meipaly agency",
    subTitle: "get to know us",
  },
  gallery: [aboutOne01, aboutOne02],
  counter: {
    title: "Company Started",
    number: 1990,
  },
};

import team01 from "@/images/team/1.jpg";
import team02 from "@/images/team/2.jpg";
import team03 from "@/images/team/3.jpg";
import team04 from "@/images/team/4.jpg";
import team05 from "@/images/team/5.jpg";

export const TeamOneData = {
  sectionContent: {
    title: "expert people",
    subTitle: "meet the team",
    text:
      "We are committed to providing our customers with exceptional service \n while offering our employees the best training.",
  },
  posts: [
    {
      image: team01,
      name: "Herman Fisher",
      designation: "General Manager",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team02,
      name: "Charlie Kennedy",
      designation: "Brand Designer",
      url: "",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team03,
      name: "Helena Adkins",
      designation: "Seinor Designer",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team04,
      name: "Virginia Farmer",
      designation: "Marketing Manager",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team05,
      name: "Philip Hansen",
      designation: "Co Founder",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
  ],
};

import video01 from "@/images/about/3.jpg";

export const VideoOneData = {
  sectionContent: {
    title: "Make amazing websites without touching cod",
    subTitle: "the only design you need",
    text:
      "Tincidunt elit magnis nulla facilisis sagittis maecenas. Sapien nunc amet ultrices, dolores sit ipsum velit purus aliquet, massa fringilla leo orci. Lorem ipsum dolors sit amet elit magnis amet ultrices purus aliquet.",
  },
  video: {
    image: video01,
    ID: "y2Eqx6ys1hQ",
    title: "Watch Video",
  },
};

export const SubscribeFormData = {
  sectionContent: {
    title: "Subscribe us",
    subTitle: "don’t miss out our latest updates",
  },
};

import testimonial01 from "@/images/home_1/t1.jpg";
import testimonial02 from "@/images/home_1/t2.jpg";
import testimonial03 from "@/images/home_1/t3.jpg";

export const TestimonialsOneData = {
  sectionContent: {
    subTitle: "our testimonials",
    title: "happy customers",
  },
  posts: [
    {
      name: "Cecilia Colon",
      designation: "Director",
      image: testimonial01,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Chase Hanson",
      designation: "CO Founder",
      image: testimonial02,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Mittie Flores",
      designation: "Manager",
      image: testimonial03,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Carrie Sims",
      designation: "Director",
      image: testimonial01,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Anne Stone",
      designation: "CO Founder",
      image: testimonial02,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Hunter Brewer",
      designation: "Manager",
      image: testimonial03,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Carrie Sims",
      designation: "Director",
      image: testimonial01,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Anne Stone",
      designation: "CO Founder",
      image: testimonial02,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Hunter Brewer",
      designation: "Manager",
      image: testimonial03,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
  ],
};

import featureTab01 from "@/images/home_1/c1.jpg";
import featureTab02 from "@/images/home_1/c2.jpg";
import featureTab03 from "@/images/home_1/c3.jpg";

export const FeatureTabData = {
  sectionContent: {
    title: "why choose us",
    subTitle: "our benefits",
    text:
      "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
  },
  posts: [
    {
      title: "Latest technology",
      content:
        "There are many variations of passages of lorem ipsum available, but the majority have  suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. ",
      image: featureTab01,
      lists: [
        {
          item: "Self-contained, state-of-the-art time clock",
        },
        {
          item: "Scalability of up to 500 employees per time clock",
        },
        {
          item: "The ability to connect up to 32 time clocks",
        },
        {
          item: "Employee self-enrollment",
        },
        {
          item: "Payroll integration",
        },
        {
          item: "Built-in backup camera to verify failed punches",
        },
      ],
    },
    {
      title: "Awesome Support",
      content:
        "There are many variations of passages of lorem ipsum available, but the majority have  suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. ",
      image: featureTab02,
      lists: [
        {
          item: "Self-contained, state-of-the-art time clock",
        },
        {
          item: "Scalability of up to 500 employees per time clock",
        },
        {
          item: "The ability to connect up to 32 time clocks",
        },
        {
          item: "Employee self-enrollment",
        },
        {
          item: "Payroll integration",
        },
        {
          item: "Built-in backup camera to verify failed punches",
        },
      ],
    },
    {
      title: "1 Click demo install",
      content:
        "There are many variations of passages of lorem ipsum available, but the majority have  suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. ",
      image: featureTab03,
      lists: [
        {
          item: "Self-contained, state-of-the-art time clock",
        },
        {
          item: "Scalability of up to 500 employees per time clock",
        },
        {
          item: "The ability to connect up to 32 time clocks",
        },
        {
          item: "Employee self-enrollment",
        },
        {
          item: "Payroll integration",
        },
        {
          item: "Built-in backup camera to verify failed punches",
        },
      ],
    },
  ],
};

export const ParallaxOneData = {
  iconName: "mei-team",
  title: "Great things in business are never done by one person.",
  specialText: "They’re done by a team of people.",
  text:
    "We are committed to providing our customers with exceptional service while \n offering our employees the best training.",
};

export const PortfolioHomeData = {
  sectionContent: {
    title: "work showcase",
    subTitle: "our portfolio",
    text:
      "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
  },
};

import video02 from "@/images/home_1/3.jpg";

export const VideoTwoData = {
  sectionContent: {
    title: "Digital Experience",
    subTitle: "how do we works",
    text:
      "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
  },
  video: {
    title: "Watch Video",
    ID: "y2Eqx6ys1hQ",
    image: video02,
  },
};

import aboutTwo01 from "@/images/home_1/2.jpg";
import aboutTwo02 from "@/images/home_1/1.jpg";

export const AboutTwoData = {
  sectionContent: {
    title: "We are the Best Website agency in The World",
    subTitle: "welcom to smart meipaly web agency",
    text:
      "We are committed to providing our customers with exceptional service while offering our employees the best training. Lorem Ipsum is simply dummy text of the printing and typesetting industry has been the industry's standard dummy text ever since the 1500s.",
  },
  button: {
    label: "Learn More",
    url: "#",
  },
  gallery: [aboutTwo01, aboutTwo02],
};

import featureTwo01 from "@/images/home_1/5.jpg";

export const FeatureTwoData = {
  sectionContent: {
    title: "real experience",
    subTitle: "our core features",
    text:
      " We are committed to providing our customers with exceptional service while offering our employees the best training. ",
  },
  posts: [
    {
      title: "No Coding Skills Require",
      text:
        "There are many variations of passages of lorem ipsum available, but the majority have suffered.",
    },
    {
      title: "Online Documentation",
      text:
        "There are many variations of passages of lorem ipsum available, but the majority have suffered.",
    },
    {
      title: "SEO Optimized",
      text:
        "There are many variations of passages of lorem ipsum available, but the majority have suffered.",
    },
  ],
  image: {
    text: "Total design freedom \n for everyone.",
    path: featureTwo01,
  },
};

export const CallToActionTwoData = [
  {
    label: "View our Recent Work",
    url: "/portfolio",
  },
  {
    label: "Reqeust a free quote",
    url: "/contact",
  },
];
